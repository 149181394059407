.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(4, 35, 54, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    opacity: 0;
    pointer-events: none;
    transition: 0.7s;
}

.modal.active {
    opacity: 1;
    pointer-events: all;

}

.modal-content {
    width: 527px;
    height: 100%;
    background: #F5F5F5;
    transform: translateX(100%);
    transition: 0.5s;
    overflow-y: auto;
}

.modal-content.active {
    transform: translateX(0%);
}

.modal-content-header {
    width: 95.5%;
    height: 50px;
    background-color: #F5F5F5;
    position: sticky;
    top: -1px;
    left: 0;
    padding-top: 30px;
    padding-left: 4.5%;
    z-index: 10;
}

.modal-content-header img {
    cursor: pointer;
}

.modal-content-container {
    width: 91%;
    height: auto;
    padding: 0px 4.5% 82px 4.5%;
    font-family: 'Mulish';
    font-style: normal;
    color: #042336;
}

@media (max-width: 700px) {
    .modal-content{
        width: 340px;
    }
}
