.first-section {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    height: 120vh;
    z-index: 10;
}

.first-section-container{
    width: 100%;
    max-width: 1440px;
    position: relative;
    overflow: visible;
}

.first-section-container-text{
    width: 100%;
    height: 100%;;
    box-sizing: border-box;
    margin-left: 120px;
    padding: 124px 0 27vh 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.first-section h1{
    order: 2;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 115%;
    text-transform: uppercase;
    color: #042336;
    width: 690px;
    margin: 0;
    position: relative;
    z-index: 10;
}

.first-section h2{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #042336;
    width: 255px;
    margin: 0;
    position: relative;
    z-index: 10;
}

.first-section-button {
    position: absolute;
    right: 120px;
    bottom: 27vh;
    z-index: 5;
}

.first-section-button div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 77px;
    height: 77px;
    border: 1px solid rgba(4, 35, 54, 0.1);
    border-radius: 10000px;
    transition: all 0.5s ease;
    position: relative;
    cursor: pointer;
}

.first-section-button div:hover {
    transform: scale(1.3);
    color: #F5F5F5;
}

.first-section-button div::before{
    content: '';
    position: absolute;
    transform-origin: center;
    width: 100%;
    height: 100%;
    background-color: #042336;
    border-radius: 1000px;
    z-index: 2;
    transform: scale(0);
    transition: transform 0.4s;   
    cursor: pointer;

}

.first-section-button div:hover::before{
    transform: scale(1.2);
}

.first-section-button svg {
    transform: rotate(180deg);
    position: relative;
    z-index: 3;
}

.first-section-container img {
    width: 100%;
    height: 100%;
}

.first-3d {
    position: absolute;
    overflow: visible;
    background-color: transparent;
}

.second-3d {
    position: absolute;
    right: 0%;
    top: 30%;
}

.third-3d {
    position: absolute;
    left: 45%;
    bottom: 10%;
    z-index: -1;
}

@media (max-width: 1000px) {
    .first-section-container-text {
        margin-left: 16px;
    }
}

@media (max-width: 720px) {
    .first-section-button{
        display: none;
    }

    .first-section-container-text {
        margin: auto 16px;
        padding-top: 0px;
    }

    .first-section-container{
        margin: auto 0px;
        padding: 114px 0 50px 0;
        justify-content: flex-start;
    }

    .first-section h1{
        font-size: 40px;
        line-height: 115%;
        text-transform: uppercase;
        color: #042336;
        width: 93%;
        margin: 0;
    }

    .first-section h2{
        font-size: 14px;
        margin-bottom: 250px;
    }

    .first-3d {
        position: absolute;
        left: 0%;
        top: 20%;
        width: 188.35px;
        height: 134.05px;
        opacity: 0.6;
    }

    .second-3d {
        position: absolute;
        right: -10%;
        top: 30%;
        width: 129.33px;
        height: 112.83px;
    }

    .third-3d {
        position: absolute;
        left: 25%;
        bottom: 20%;
        z-index: -1;
        width: 170px;
        height: 222.85px;   
    }
}



.word {
    display: inline-block;
    overflow: hidden;
}

.word span {
    display: inline-block;
    animation-name: slideIn;
    animation-duration: 1.3s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
}

.word span.without-delay {
    animation-delay: 0s;
}

@keyframes slideIn {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
  }