

.margin-class{
    margin-bottom: 750px;
    background-color: #f5f5f5;
}

.margin-special-class{
    margin-bottom: 0px;
}

@media (max-width: 720px) {
    .margin-class{
        margin-bottom: 0px;
    }
}