.service-page{
    width: 100%;
    max-width: 1440px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0 auto 0 auto;
    background: #F5F5F5;
    color: #042336;
}

.service-page-title {
    border-bottom: 1px solid rgba(35, 35, 35, 0.1);
    padding: 50px 120px;
}

.service-page h1 {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 115%;
    text-transform: uppercase;
    margin: 0;
}

.service-page-title span{
    color: #042336;
}

.service-page-back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
    transition: transform 0.5 easy;
}

.service-page-back-button:hover span {
    transform: translateX(2px);
}

.service-page-back-button:hover img {
    transform: translateX(-2px);
}

.service-page-main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    background-color: #F5F5F5;
    height: auto;
    position: relative;
}

@media (max-width: 1235px) {
    .service-page-main{
        gap: 10px;
    }

    .service-desription {
        width: 50%;
    }
    
}

.service-desription{
    display: flex;
    flex-direction: column;
    width: 61%;
    border-right: 1px solid rgba(35, 35, 35, 0.1);
}

.zmist {
    padding: 32px 70px 0 120px;
}

.service-description-block {
    padding: 0 70px 0 120px;
}


.service-description-block h2,
.service-description-block h5 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    margin: 0 0 22px 0;
    position: relative;
}

.service-description-block p {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    margin: 0 0 40px 0;
}

.service-description-block h3 {
    color: #3693C8;
    width: 100px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    margin: 0 0 8px 0;
}

.svg-arrow {
    visibility: hidden;
    display: flex;
    align-items: center;
}

#list .svg-arrow {
    visibility: visible;
}

#list{
    margin: 0 0 48px 0;
    color: #1C3849;
}

.title-with-svg {
    color: inherit;
    height: 46px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid rgba(35, 35, 35, 0.1);
    transition: all 0.3s ease-in-out;
}

#list h2 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 170%;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title-with-svg:hover {
    color: #3693C8;
    border-bottom: 1px solid rgba(54, 147, 200, 0.10);
}

.service-description-block ol,
.service-description-block ul{
    margin: 0 0 24px 0;
    padding: 0 0 0 20px;
    opacity: 0.7;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    width: 80%;
}

.service-description-block ul li,
.service-description-block ol li{
    margin-bottom: 8px;

}

.service-description-block h4{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    margin-bottom: 24px;
    border-left: 4px solid #042336;
    padding-left: 16px;
    overflow-wrap: break-word;
}


.service-description-block a {
    color: #3693C8;
    text-decoration: underline;
}

.service-page-form{
    width: 100%;
    margin: 0 0 0 0;
}

.service-page-form input{
    width: 95%;
    padding-left: 5%;
    background: rgba(35, 35, 35, 0.05);
    color: #042336;
}

.service-page-form input:focus {
    border: 1px solid rgba(4, 35, 54, 0.4);
}

.service-page-form p {
    margin-bottom: 28px;
}

.service-page-form input.has-error,
.service-page-form input.has-error:focus {
    border: 1px solid red;
}

.service-page-form .phone-input:focus {
    border: 1px solid rgba(4, 35, 54, 0.4) !important;
}

.service-page-form input::placeholder{
    color: rgba(4, 35, 54, 0.2);
}

.service-page-form .phone-input{
    width: 100% !important;
    background: rgba(35, 35, 35, 0.05) !important;
    color: #042336 !important;
}

.service-page-form .phone-input.has-error{
    border: 1px solid red !important;
  }

.service-page-form .flag-dropdown{
    background: rgb(235 235 235) !important;
    margin-left: 12px !important;
  }

.service-page-form .open,
.service-page-form .selected-flag:focus,
.service-page-form .selected-flag:hover{
    background: rgb(235 235 235) !important;
}

.service-page-form .arrow.up {
    border-bottom: none !important;
}

.service-page-form .country-list {
    opacity: 1;
  }

.service-page-form .arrow {
    border-top: 4px solid #042336 !important;
}

.service-page-form .form-button {
    width: 100%;
    height: 79px;
    background: #042336;
    border: 1px solid #042336;
    border-radius: 100px;
    color: #F5F5F5;
    margin-top: 48px;
  }

  .service-page-form .form-button::after{
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    border: 0px solid #042336;
    background-color: transparent;
    border-radius: 100px;
    z-index: 1;
    cursor: pointer;
}

.service-page-form .form-button::before{
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    border-radius: 100px;
    z-index: 0;
    transition: transform 0.5s;
    transform: translateX(100%);
    cursor: pointer;
}

.service-page-form .form-button:hover{
    color: #042336;
}

.service-page-form .form-button:hover::before{
    transform: translateX(0%);
}

.service-materials-block{
    width: 27%;
    height: 750px;
    position: sticky;
    top: 34px;
    padding-top: 39px;
    margin-right: 120px;
}

.service-materials-block-title{
    display: block;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 170%;
    margin-bottom: 16px;
}

.material-item{
    width: 100%;
    height: auto;
    background-color: rgba(35, 35, 35, 0.05);;
    margin-bottom: 12px;
    padding: 20px 0 20px 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out
}

.material-item-container {
    width: auto;
    height: 100%;
    margin: 0 40px 0 24px;
    display: flex;
    flex-direction: column;
}

.material-item-title{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 170%;
    margin-bottom:10px;
}

.material-item-text{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    opacity: 0.5;
    margin-bottom: 25px;
}

.material-item-link {
    display: flex;
    align-items: center;
}

.material-item-link span {
    margin-right: 4px;
}

.material-item-link span {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.material-item-link svg {
    transform: translateX(0px);
    transition: transform 0.5s ease;
}

.material-item:hover .material-item-link svg{
    transform: translateX(5px);
}

.material-item:hover {
    background: rgba(35, 35, 35, 0.15);
}


.service-materials-block-info{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 12px;
    margin-bottom: 56px;
}

.service-materials-block-info div{
    width: 45%;
    display: flex;
    flex-direction: column;
}

.service-materials-block-info span{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    opacity: 0.5;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-bottom: 4px;
}

.service-materials-block-info a{
    color: #042336;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    white-space: nowrap;
}

.service-materials-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.service-materials-button{
    width: 116px;
    height: 53px;
    border: 1px solid #042336;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #042336;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    isolation: isolate;
}

.service-materials-button::before{
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    background-color: #042336;
    cursor: pointer;
    position: absolute;
    left: 0%;
    top: 0%;
    content: '';
    cursor: pointer;
    transform: translateX(100%);
    transition: transform 0.5s ease;
}

.service-materials-button:hover{
    color:#F5F5F5;
}

.service-materials-button:hover::before{
    transform: translateX(0);
}

.service-materials-button svg {
    position: relative;
    z-index: 2;
    margin-right: 6px;
}

.service-materials-button span {
    position: relative;
    z-index: 2;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
}



.modal-content-container h2 {
    margin: 0;
    font-weight: 800;
    font-size: 30px;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 44px;
}

.modal-content-container h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
}

.modal-content-container h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    margin-bottom: 24px;
    border-left: 4px solid #042336;
    padding-left: 16px;
}

.modal-content-container p {
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    margin-bottom: 24px;
    display: flex;
}

.modal-content-container ul {
    padding-left: 20px;
    margin-bottom: 24px;
    opacity: 0.7;
}

.modal-content-container li {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
}

.modal-content-container a {
    color: #3693C8;
    display: contents;
}

.modal-content-container img {
    margin: 32px auto ;
}




@media (max-width: 1435px) {
    .service-desription{
        /* width: 50%; */
    }

    .service-materials-block{
        width: 30%;
    }
}

@media (max-width: 1100px) {
    .service-page-main{
        display: flex;
        flex-direction: column;
    }

    .service-desription{
        width: 100%;
        border: none;
    }

    .service-materials-block{
        position: relative;
        width: 382px;
        margin-left: 120px;
        height: auto;
    }

    .service-materials-button-wrapper{
        display: none;
      }
}

@media (max-width: 800px){
    .service-page-form .form-button {
        margin-bottom: 119px;
      }
}

@media (max-width: 600px){
    .service-page-title{
        padding: 24px 16px;
      }

      .service-page-back-button{
        font-size: 14px;
      }

      .service-page h1{
        font-size: 24px;
      }

      .service-page-main{
        width: 90%;
        padding: 23px 16px 0px 16px;
      }

      .zmist,
      .service-description-block{
        padding: 0;
      }

      .service-materials-block{
        position: relative;
        width: 343px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 0 0 40px 0;
      }

      .service-materials-block.sticky{
        position: relative;
      }

      .service-materials-block-info{
        display: none;
      }

}









.loading-div-service {
    background-color: #F5F5F5;
    width: 100%;
    height: calc(100vh - 64px);
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: visible;
    top: 64px;
    left: 1px;
}

.loading-service {
    background-color: #F5F5F5;
    width: 100%;
    height: calc(100vh - 64px);;
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: visible;
    top: -1px;
    left: 1px;
    opacity: 1;
    transition: opacity 0.8s ease-in-out, visibility 0.2s ease-in-out 0.8s;
    
}

.loading-service.hide {
    opacity: 0;
    visibility: hidden;
}
