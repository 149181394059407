.modal-form {
    height: 100vh;
    width: 100vw;
    background-color: rgba(28, 56, 73, 0.2);
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.7s;
}

.modal-form.active {
    opacity: 1;
    pointer-events: all;
}

.modal-form-content {
    width: 413px;
    height: auto;
    padding: 32px 40px;
    background-color: #F5F5F5;
    color: #042336;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
}

.modal-form-content-close-button {
    height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    align-self: flex-end;
    font-family: 'Mulish';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.14px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

.modal-form-content-close-button span {
    margin-bottom: 1.5px;
}

.modal-form-content-close-button:hover {
    opacity: 0.5;
}

.modal-form-content-message {
    text-align: center;
    font-family: NAMU;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 115%;
}

.modal-form-content-button {
    width: 183px;
    height: 79px;
    border-radius: 100px;
    border: 1.5px solid #DEE0E2;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    position: relative;
    isolation: isolate;
}

.modal-form-content-button span {
    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #042336;
    position: relative;
    z-index: 2;
}

.modal-form-content-button:hover span{
    color:#F5F5F5;
}

.modal-form-content-button::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #042336;
    border-radius: 100px;
    z-index: 1;
    transition: transform 0.5s ease-in-out;
    transform: translateX(100%);
}

.modal-form-content-button:hover::before{
    transform: translateX(0);
}


@media (max-width: 600px) {
    .modal-form-content {
        width: calc(100% - 64px);
        padding: 32px 16px;
    }

    .modal-form-content-button {
        width: 100%;
        height: 65px;
    }
}

