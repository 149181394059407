@import '../../fonts/fonts.css';


.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Mulish';
    font-style: normal;
    background-color: #F5F5F5;
}

.about-page-header {
    width: 100%;
    height: calc(100vh - 198px);
    color: #042336;
    padding-top: 57px;
    padding-bottom: 77px;
    display: flex;
    justify-content: center;
    position: relative;
}

.small-container-header {
    width: 100%;
    margin: 0 120px;
    max-width: 1200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}


.about-page-header-text {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about-page-header-text span:nth-child(1) {
    width: 310px;
    height: 300px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 140px;
}

.about-page-header-text span:nth-child(2) {
    width: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.about-page-header-text span:nth-child(3) {
    min-width: 550px;
    width: 100%;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
}

.about-page-header-photo {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 45%;
    height: 100%;
    overflow: hidden;
}

.background-team-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../images/team-photo-main.jpg') ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.about-page-main{
    width: 100%;
    background: #042336;
    height: 795px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 261px;
    position: relative;
}

.about-page-main a{
    color: #F5F5F5;
}

.small-container-main {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 1440px;
    padding-top: 210px;
}

.third-3d {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.about-page-main-first-text {
    width: 271px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #F5F5F5;
    margin-left: 120px;
    position: relative;
    z-index: 10;
}

.about-page-main-second-text {
    color: #F5F5F5;
    margin-right: 120px;
    position: relative;
    z-index: 10;
}

.about-page-main-second-text span {
    display: inline-block;
    width: 584px;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 55px;
}

.codecs-button {
    width: 266px;
    height: 79px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #F5F5F5;
    border-radius: 100px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s ease;
    isolation: isolate;
}

.codecs-button h5 {
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    z-index: 2;
}

.codecs-button:hover h5{
    color:#042336;;
}


.codecs-button::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    border-radius: 1000px;
    z-index: 1;
    transition: transform 0.5s;
    transform: translateX(100%);
}

.codecs-button:hover::before{
    transform: translateX(0);
}

.about-page-main-special {
    height: 130px;
    width: 100%;
    padding: 51px 0px 77px 0px;
    position: absolute;
    bottom: -261px;
    background-color: #F5F5F5;
    display: flex;
    color: #042336;
    border-bottom: 1px solid #E9EBEC;
}

.about-page-special-first-text {
    display: flex;
    flex-direction: column;
    margin-left: 120px;
    margin-right: 42px;
    position: relative;
    z-index: 12;
}

.about-page-special-first-text span:nth-child(1) {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    margin-bottom: 19px;
}

.about-page-special-first-text span:nth-child(2) {
    width: 571px;
    font-family: 'NAMU';
    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
}

.about-page-special-second-text {
    padding-top: 44px;
    width: 358px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    position: relative;
    z-index: 12;
}

.slider-team-member {
    display: flex;
    flex-direction: row;
    background: #F5F5F5;
    color: #042336;
}

.slider-team-member-photo {
    width: 47%;
    height: 100%;
}

.slider-team-member-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-team-member-content{
    width: 53%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
}

.member-content-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    width: 90%;
    max-width: 850px;
}

.slider-team-member-comment {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.slider-team-member-comment span{
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
}

.slider-team-member-comment-image img {
    margin-bottom: 12px;
    width: 100%;
    height: auto;
}

.slider-team-member-comment-image{
    width: 45px;
}

.slider-team-member-person {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;

}

.slider-team-member-person span:nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    text-align: flex-start;
    white-space: pre-line;
}

.slider-team-member-person span:nth-child(2) {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    opacity: 0.5;
    margin-top: 12px;
    max-width: 350px;
;
}

.about-page-slider {
    width: 100%;
}

.about-page-slider .slick-slider {
    overflow: hidden;
    /* position: relative !important; */
    width: 100% !important;
    height: 100% !important; 
}

.about-page-slider .slick-track {
    display: flex;
}

.about-page-slider .slick-arrow{
    top: auto !important;
    bottom: 13px !important;
    width: 77px !important;
    height: 77px !important;
    border: 1px solid #042336 !important;
    border-radius: 10000px;
    color: #042336 !important;
    transition: all 0.4s ease;  
    scale: 1;
    transform-origin: top !important;
    /* position: absolute !important; */
}

.about-page-slider .slick-arrow:not(:empty) {
    color: transparent !important;
    font-size: 0.5px;
  }

.about-page-slider .slick-arrow:hover {
    scale: 1.3;
}

.about-page-slider .slick-arrow::after {
    content: '' !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #042336;
    border-radius: 1000px;
    z-index: -1;
    transform: scale(0);
    transition: transform 0.4s ease;   
    cursor: pointer;
}

.about-page-slider .slick-arrow:hover::after {
    transform: scale(1.2);
}

.about-page-slider .slick-prev {
    left: 47% !important;
    color: #042336 !important;
    z-index: 3;
    margin-left: 50px;
}

.about-page-slider .slick-prev::before {
    content: url('../../images/left-icon.svg') !important;
    color: #042336 !important;
    opacity: 1 !important;
}

.about-page-slider .slick-prev:hover:before {
    filter:   saturate(0) invert(100%);
    color: #F5F5F5 !important;
}

.about-page-slider .slick-next {
    left: 47% !important;
    color: #042336 !important;
    margin-left: 150px;
}

.about-page-slider .slick-next::before {
    content: url('../../images/right-icon.svg') !important;
    color: #042336 !important;
    opacity: 1 !important;
}

.about-page-slider .slick-next:hover:before {
    filter:   saturate(0) invert(100%);
    color: #F5F5F5 !important;
}



.about-page .carousel, 
.about-page-carousel {
    background-color: #042336;
    color: #F5F5F5;
}

.about-page-carousel{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.about-page-carousel .carousel .slick-arrow {
    color: #F5F5F5 !important;
    border: 1px solid #F5F5F5 !important;
}

.about-page-carousel .carousel .slick-arrow::after {
    background-color: #F5F5F5;
}

.about-page-carousel .carousel .slick-prev::before {
    content: url('../../images/left-icon-white.svg') !important;
}

.about-page-carousel .carousel .slick-next::before {
    content: url('../../images/right-icon-white.svg') !important;
}

.about-page-carousel .carousel .slick-disabled{
    border: 1px solid rgb(179 172 172 / 50%) !important;
    filter:   saturate(0) invert(70%);
    color: #F5F5F5 !important;
}

.about-page-carousel .carousel-item {
    background: #1C3849;
}

.about-page-carousel .carousel-item:hover {
    background: #3E5E71;
}

.about-page-carousel .carousel-item-link{
    color:#F5F5F5;
}

.about-page-carousel .carousel-item-title {
    color: #F5F5F5;
}

.about-page-carousel .carousel-item-text {
    color: #F5F5F5;
}

.white-one,
.white-two,
.white-three {
    position: absolute;
}

.white-one img,
.white-two img,
.white-three img {
    width: 100%;
}

.white-two {
    left: 0px;
    top: 0px;
}

.white-one {
    right: 0px;
    top: 0px;
}

.white-three {
    left: 140px;
    bottom: 61px;
}

.small-container-header-sm {
    display: none;
}




@media (max-width: 1300px) {

    .about-page-header {
        height: auto;
        padding-bottom: 0;
    }
    
    .small-container-header {
        display: none;
    }

    .small-container-header-sm {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .about-page-header-text-sm {
        width: calc(100% - 80px);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 50px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .about-page-header-text-sm div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px;
        
    }
    
    .text-1 {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 18px */
        letter-spacing: 1.8px;
        text-transform: uppercase;
    }

    .text-2 {
        font-family: NAMU;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 48px */
        text-transform: uppercase;
    }

    .text-3 {
        min-width: 342px;
        max-width: 342px;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    .about-page-header-image {
        width: 100%;
        height: 470px;
        background-image: url('../../images/team-photo-main.jpg') ;
        background-size: cover;
        background-position: 55% 20%;
        background-repeat: no-repeat;
    }

    /* .about-page-header-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    } */

    

    .about-page-main-first-text {
        margin-left: 50px;
    }

    .about-page-main-second-text {
        margin-right: 50px;
    }

    .about-page-special-first-text {
        margin-left: 50px;
    }

    .slider-team-member-photo {
        height: 522px;
    }

    .slider-team-member-comment span {
        font-size: 14px;
    }

    .slider-team-member-person span:nth-child(2){
        font-size: 9px;
        max-width: 280px;
    }

}

@media (max-width: 900px) {
    .about-page-header-text-sm {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .text-3 {
        min-width: unset;
        max-width: unset;
        width: 100%;
    }
    
    .about-page-main-second-text span {
        width: 324px;
    }

    .about-page-header-text span:nth-child(1) {
        width: 500px;
    }

    .about-page-special-first-text span:nth-child(2) {
        width: 324px;
        font-size: 20px;
    }

    .about-page-special-second-text {
        padding-top: 0px;
    }

    .about-page-slider .slick-arrow {
        width: 50px !important;
        height: 50px !important;
        bottom: 10px !important;
    }

    .slider-team-member-person {
        margin-bottom: 40px;
    }

    .slider-team-member-content{
        align-items: flex-start;
    }

    .slider-team-member-comment{
        margin-top: 0;
        margin-bottom: 24px;
    }

    .member-content-container{
        justify-content: space-between;
        align-items: flex-start;
        height: 80%;
    }

    .about-page-slider .slick-next {
        margin-left: 110px;
    }

    .about-page-slider .slick-prev {
        margin-left: 35px;
    }

    .slider-team-member-photo {
        height: auto;
    }
    
}

@media (max-width: 700px) {

    .about-page-header {
        padding-top: 40px;
    }

    .about-page-header-text-sm {
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
    }

    .text-1 {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; 
        letter-spacing: 1.8px;
        text-transform: uppercase;
    }

    .text-2 {
        font-family: NAMU;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 115%; /* 27.6px */
        text-transform: uppercase;
    }

    .text-3 {
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    

    .small-container-main {
        flex-direction: column;
        padding-top: 122px;
        justify-content: flex-start;
        height: 555px;
    }

    .about-page-main {
        flex-direction: column;
        justify-content: flex-start;
        height: 937px;
        margin-bottom: 0;
    }

    .about-page-main-first-text {
        margin-left: 16px;
        font-size: 12px;
        margin-bottom: 16px;
        position: relative;
        z-index: 11;
    }

    .about-page-main-second-text span {
        margin-left: 16px;
        width: 100%;
        font-size: 14px;
        position: relative;
        z-index: 11;
    }

    .about-page-main-second-text {
        z-index: 11;
    }

    .about-page-main-second-text div {
        margin-left: 16px;
        margin-right: 16px;
        width: 100%;
    }

    .about-page-main-special {
        height: 268px;
        background-color: #042336;
        color: #F5F5F5;
        flex-direction: column;
        border-bottom: none;
    }

    .about-page-special-first-text{
        margin-left: 16px;
    }

    .about-page-special-first-text span:nth-child(2){
        width: 269px;
        font-size: 24px;
        margin-bottom: 30px;
    }

    .about-page-special-second-text{
        width: 306px;
        margin-left: 16px;
        font-size: 14px;
        line-height: 170%;
    }

    .third-3d{
        z-index: 9;
    }

    .white-two {
        width: 250px;
    }

    .white-one{
        width: 250px;
        top: 0px;
        z-index: 5;
    }

    .white-three{
        z-index: 6;
        bottom: -100px;
        left: 130px;
    }

    .slider-team-member-photo {
        height: auto;
        width: 100%;
    }

    .slider-team-member-content{
        width: 100%;
        height: 380px;
        margin-left: 0;
        padding-bottom: 105px;
    }

    .member-content-container {
        width: 100%;
        height: 380px;
        justify-content: space-between;
    }

    .slider-team-member-photo img {
        width: 100%;
    }

    .slider-team-member {
        flex-direction: column;
        margin-bottom: 0;
    }

    .slider-team-member-comment {
        margin: 30px 16px 24px 16px;
    }

    .slider-team-member-comment-image{
        width: 25px;
    }

    .slider-team-member-comment span {
        width: 100%;
        font-size: 16px;
        line-height: 150%;
    }

    .slider-team-member-person span:nth-child(2) {
        font-size: 12px;
    }

    .slider-team-member-person {
        align-items: flex-start;
        margin-left: 16px;
        margin-right: 16px;
        width: 100%;
    }

    .about-page-slider .slick-track {
        background-color: #F5F5F5;
    }

    .about-page-slider .slick-arrow {
        bottom: 0px !important;
    }

    .about-page-slider .slick-prev {
        left: 20px !important;
        margin-left: 0;
    }

    .about-page-slider .slick-next {
        left: 90px !important;
        margin-left: 0;
    }

}

@media (max-width: 500px) {
    .about-page-header-image {
        height: 283px;
        background-size: 150% auto;
        background-position: 170% auto;
        background-repeat: no-repeat;
    }
}

@media (min-width: 2000px){
    .about-page-header-photo {
        width: 40%;
    }
}

@media (min-width: 1600px) {
    .about-page-header-text span:nth-child(2) {
        margin-top: 90px;
    }
}








