.header{
    width: 100%;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #DEE0E2;
    height: 64px;
    position: sticky;
    top: 0px;
    z-index: 1000;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 700ms;
}

.header.hide {
    top: -100%;
  }

.header-container{
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto 18px;
}

.logo-wrapper{
    width: 110px;
}

.search-icon {
    cursor: pointer;
}

.nav ul{
    display: flex;
    flex-direction: row;
    gap: 32px;
    list-style: none;
}

.nav a{
    text-decoration: none;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    letter-spacing: -0.01em;
    position: relative;
    z-index: 2;
}

.nav li{
    width: 85px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#042336;
    border-radius: 1000px;
    border: 1px solid transparent;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
    position: relative;
    isolation: isolate;
}

.nav span {
    z-index: 3;
}

.nav li:hover {
    color:#F5F5F5;
}

.nav li::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #042336;
    border-radius: 1000px;
    z-index: 1;
    transition: transform 0.5s;
    transform: translateX(100%);
}

.nav li:hover::before{
    transform: translateX(0);
}

.nav li:active::before{
    background-color: #D6D6D6;
}

.nav li.active{
    border-color: #042336;
}

.right-menu {
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-trigger{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7.5px;
    color: #042336;
    text-decoration: none;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.dropdown-container{
    margin-left: 27px;
    position: relative;
}

.dropdown-item img {
    margin-right: 12px;
}

.dropdown-menu{
    width: 120px;
    height: auto;
    padding: 8px 0;
    position: absolute;
    top: 50px;
    right: 0px;
    background: #F5F5F5;
    border: 1px solid rgba(4, 35, 54, 0.1);
    border-radius: 8px;
}

.dropdown-menu div {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown-menu button {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #042336;
    line-height: 150%;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
}

.dropdown-menu button:hover{
    background-color: #E9EBEC;
}


.dropdown-menu::before{
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
}

.dropdown-menu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 500ms ease;
}

.dropdown-menu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 500ms ease;
}

.dropdown-trigger.active{
    opacity: 0.5;
}

.dropdown-trigger.inactive{
    opacity: 1;
}

.down-arrow.active{
    transform: rotate(180deg);
    transition: 500ms ease;
}

.down-arrow.inactive{
    transform: rotate(0deg);
    transition: 1000ms ease;
}

.menu-icon{
    display: none;
}

.close-icon{
    display: none;
}

.language-switch{
    display:none;
}

.search-input{
    height: calc(100vh - 64px);
    width: 100vw;
    background-color: rgba(28, 56, 73, 0.2);
    position: fixed;
    z-index: 900;
    top: 65px;
    left: 0;
    transition: 0.7s;
    opacity: 0;
    pointer-events: none;
}

.search-input.active {
    opacity: 1;
    pointer-events: all;
}

.search-input-container{
    height: 64px;
    width: 100%;
    padding-top: 4px;
    background-color: #F5F5F5;
    transform: translateY(-100%);
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-input-container.active {
    transform: translateY(0%);
}

.input-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-container input{
    width: 80%;
    background-color: transparent;
    padding-left: 0;
    font-family: NAMU;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    color: #042336;
}

.search-input-container > .input-container > input{
    color: #042336;
}

.search-input-container > .input-container > input:focus {
    font-family: NAMU;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    color: #042336;
}

.input-container input::placeholder {
    font-family: NAMU;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    color: #042336;
    opacity: 0.1;
    padding-left: 5px;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none
  }

  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  

  .search-button {
    height: 38px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 1000px;
    border: 1px solid #000;
    font-size: 14px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    position: relative;
    isolation: isolate;
}

.search-button span {
    color:#042336;
    z-index: 2;
}

.search-button:hover span{
    color:#F5F5F5;
}

.search-button::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #042336;
    border-radius: 1000px;
    z-index: 1;
    transition: transform 0.5s;
    transform: translateX(100%);
}

.search-button:hover::before{
    transform: translateX(0);
}

.search-close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color:#042336;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.search-close-icon {
    display: none;
}

.header-icons{
    display: none;
}

@media (max-width: 920px) {
    .container{
        height: 56px;
        margin: auto 16px auto 24px;
    }

    .nav{
        display: none;
    }

    .nav ul{
        display: flex;
        flex-direction: column;
        gap: 24px;
        list-style: none;
        margin: 0;
        padding: 68px 0 0 0;
    }

    .right-menu{
        display: none;
    }

    .menu-icon{
        display: block;
        margin-left: 16px;
    }

    .close-icon{
        display: none;
        margin-left: 16px;
    }

    .menu-icon.active{
        display: none;
    }

    .menu-icon.inactive{
        display: block;
    }

    .close-icon.active{
        display: block;
    }

    .close-icon.inactive{
        display: none;
    }

    .header-icons {
        display: flex;
        flex-direction: row;
    }

    .nav.active{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        height: 100dvh;
        position:absolute;
        background-color: #042336;
        top: 56px;
        left: 0;
    }

    .nav li{
        color: #F5F5F5;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        text-transform: uppercase;
        width: 170px;
        height: 61px;
    }

    .nav li::before{
        transform: translateX(0%);
    }

    .nav a {
        font-size: 24px;
    }

    .nav li.active{
        border-color: #F5F5F5;
    }

    .nav li:hover{
        border-color: #F5F5F5;
    }

    .language-switch{
        display: flex;
        margin-top: 160px;
        gap: 24px;
    }

    .language-switch span{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #F5F5F5;
        opacity: 0.4;
    }

    .line{
        width: 1px;
        height: auto;
        background-color: #F5F5F5;
    }

    .search-button{
        display: none;
    }

    .search-close-button {
        display: none;
    }

    .input-container{
        width: 95%;
        height: 100%;
    }

    .input-container input{
        width: 90%;
        margin: 0;
        height: 90%;
        display: flex;
        align-items: center;
        font-size: 24px;
    }

    .input-container input::placeholder{
        font-size: 24px;
    }

    .search-input-container > .input-container > input:focus {
        font-size: 24px;
    }

    .search-close-icon {
        display: block;
    }

}