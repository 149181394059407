.loading-div {
    background-color: #F5F5F5;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: visible;
    top: 0;
    left: 0;
}

.loading-div img {
    animation: loading-animation 1.5s infinite;
}

.loading {
    background-color: #F5F5F5;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: visible;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.8s ease-in-out, visibility 0.2s ease-in-out 0.8s; 
}

.loading img {
    animation: loading-animation 1.5s infinite;
}

.loading.hide {
    opacity: 0;
    visibility: hidden;
}

@keyframes loading-animation {
    0% { opacity: 1; }
    50% { opacity: 0.2; }
    100% { opacity: 1; }
}