.contacts-block{
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(245, 245, 245, 0.1);
}

.contacts-block-container{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0px 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Mulish';
}

.contacts-border {
  height: 100%;
  width: 1px;
  background-color: rgba(245, 245, 245, 0.1);
}

.contacts-first-block {
    display: flex;
    flex-direction: column;
    width: 59%;
    position: relative;
}

.contacts-first-block span:nth-child(1) {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    opacity: 0.5;
    text-transform: uppercase;
    margin-top: 40px;
  }

  .contacts-first-block span:nth-child(2) {
    width: 404px;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 100%;
    text-transform: uppercase;
    margin-top: 81px;
  }

  .contacts-first-block span:nth-child(3),
  .contacts-first-block span:nth-child(5) {
    width: 193px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-top: 24px;
  }

  .contacts-first-block span:nth-child(4) {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 115%;
    text-transform: uppercase;
    margin-top: 169px;
    margin-bottom: 24px;
  }

  .video-item {
    width: 112px;
    height: 22px;
    padding: 5px 15px;
    width: auto;
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 12px;
    border: 1px solid var(--light, #F5F5F5);
    border-radius: 40px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .video-item::before{
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    border: 1px solid var(--light, #F5F5F5);
    border-radius: 40px;
    z-index: 0;
    transition: transform 0.5s ease;
    transform: translateX(100%);
    cursor: pointer;
}


.video-item:hover{
  color: #042336;
}

.video-item:hover::before{
  transform: translateX(0%);
}

.video-item svg {
  position: relative;
  z-index: 5;
  margin-top: 2px;
}

  .video-item span {
    width: auto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 0% !important;
    text-transform: none !important;
    margin-top: 0 !important;
    color: inherit !important;
    position: relative;
    z-index: 5;
  }

  .video-item-block {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 60px;
  }

  .video-item-block a{
    color: inherit;
  }

  .corner-icon {
    position: absolute !important;
    top: 40px;
    right: 50px;
  }

  .corner-icon-small {
    display:none;
  }

  .contacts-second-block {
    width: 40%;
    margin-left: 40px;
    margin-top: 40px;
  }

  form{
    display: flex;
    flex-direction: column;
    margin-top: 74px;
  }

  input{
    width: 423px;
    height: 57px;
    background: #1C3849;
    border: none;
    margin-bottom: 4px;
    color: #F5F5F5;
    padding-left: 16px;
    border: 1px solid transparent;
    border-radius: 0;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  input.has-error,
  input.has-error:focus {
    border: 1px solid red;
  }

  form p {
    color: #F17E7E;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0 0 24px 0;
    height: 0;
    opacity: 0;
  }

  form p.has-error {
    opacity: 1;
  }

  input::placeholder{
    color: rgba(245, 245, 245, 0.2);
  }

  input:focus{
    outline: none;
    border: 1px solid rgba(245, 245, 245, 0.2);
  }

  form span {
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 4px;
  }

  .phone-input {
    width: 441px !important;
    height: 57px !important;
    background: #1C3849 !important;
    border: 1px solid transparent !important;
    margin-bottom: 24px !important;
    color: #F5F5F5 !important;
    padding-left: 54px !important;
    border-radius: 0px !important;
    font-size: 16px !important;
  }

  .phone-input:focus {
    border: 1px solid rgba(245, 245, 245, 0.2) !important;
  }

  .phone-input.has-error{
    border: 1px solid red !important;
  }

  .react-tel-input{
    margin-bottom: 4px;
  }

  .flag-dropdown{
    background: #1C3849 !important;
    border: none !important;
    margin-left: 12px !important;
    top: 9px;
    height: 90%;
  }

  .react-tel-input .flag-dropdown {
    top: 2px !important;
  }

  .open,
  .selected-flag:focus,
  .selected-flag:hover{
    background: #1C3849 !important;
  }

  .arrow {
    border-top: 4px solid #F5F5F5 !important;
  }

  .form-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 441px;
    height: 79px;
    background: #F5F5F5;
    border-radius: 100px;
    border: 1px solid #DEE0E2;
    color: #1C3849 ;
    margin-top: 50px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
    isolation: isolate;
  }

  .form-button span{
    color: inherit !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-bottom: 0 !important;
    position: relative !important;
    z-index: 1;
  }

  .form-button::after{
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    border: 0px solid #DEE0E2;
    background-color: transparent;
    border-radius: 100px;
    z-index: 1;
    cursor: pointer;
}

  .form-button::before{
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: #042336;
    border-radius: 100px;
    z-index: 0;
    transition: transform 0.5s;
    transform: translateX(100%);
    cursor: pointer;
}

.form-button:hover{
    color: #DEE0E2;
}

.form-button:hover::before{
    transform: translateX(0%);
}

.country-name {
  color: #042336;
}

.country-list {
  opacity: 1;
}

.contacts-second-block-title {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .contacts-block-for-mobile{
    display: none;
  }

  .contacts-block .footer-site-info {
    display: none;
  }

  .contacts-block .footer-site-info a{
    width: 100%;;
  }

  @media (max-width: 1200px){
    .form-button{
        width: 300px;
    }

    input{
        width: 284px;
  }

  .phone-input {
    width: 300px !important;
  }

  /* .video-item-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
  } */
}

@media (max-width: 800px){
    .contacts-block-container {
        display: flex;
        flex-direction: column;
        margin: 0px 16px;
    }
    .contacts-first-block {
        width: 100%;
        border: none;
    }

    .contacts-second-block {
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
    }

    .contacts-first-block span:nth-child(2) {
        width: 100%;
        font-size: 35px;
        margin-top: 49px;
    }

    .contacts-first-block span:nth-child(3) {
        margin-top: 16px;
    }

    .contacts-first-block span:nth-child(4){
        display: none;
    }

    .contacts-first-block span:nth-child(5){
        display: none;
    }

    .video-item-block{
        display: none;
    }

    .contacts-second-block-title{
        display: none;
    }

    input{
        margin-bottom: 8px;
        width: auto;
        height: 52px;
    }

    .phone-input {
        width: 100% !important;
        height: 52px !important;
    }

    .form-button {
        width: 100%;
        height: 79px;
        margin-top: 48px;
        margin-bottom: 305px;
    }

    .corner-icon {
        display: none;
      }
    
    .corner-icon-small {
        display: inline;
        position: absolute;
        top: 40px;
        right: 0px;
    }

    .contacts-block-for-mobile{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 795px;
    }

    .contacts-block-for-mobile span:nth-child(1){
        text-align: center;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 115%;
        width: 70%;
        color: #F5F5F5;
        text-transform: uppercase;
        opacity: 1;
    }

    .contacts-block-for-mobile span:nth-child(2) {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-top: 8px;
        text-transform: none;
    }

    .video-item-block-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        width: 100%;
    }

    .video-item-block-mobile  a{
      color: inherit;
      width: 60%;
    }

    .video-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        height: 45px;
        width: 100%;
    }
}
  
  
  
  
  