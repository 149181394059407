.special-footer {
    position: relative;   
}


.special-footer .footer-container{
    height: 432px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin: auto 201px auto 120px;
    padding-top: 64px;
}

.special-footer .footer-head div {
    display: none;
}

.special-footer .footer-site-info {
    display: none;
}

.special-footer .footer-head span {
    width: 510px;
}

.special-footer .footer-main{
    margin-top: 0px;
    width: auto;
}

.special-footer .footer-bottom {
    position:absolute;
    bottom: 59px;
}

.special-footer .footer-nav{
    margin-right: 129px;
}

@media (max-width: 920px) {
    .special-footer .footer-head span {
        width: 300px;
    }

    .special-footer .footer-container {
        margin: auto 80px auto 80px;
    }

    .special-footer .footer-nav {
        margin-right: 70px;
    }
}

@media (max-width: 720px) {
    .special-footer .footer-container {
        height: 490px;
        margin: auto 16px auto 16px;
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .special-footer .footer-nav-and-socmedia{
        margin-top: 48px;
    }

    .special-footer .footer-head span {
        font-size: 24px;
    }

    .special-footer .footer-head {
        height: auto;
    }

    .special-footer .footer-bottom {
        bottom: 30px;
        gap: 16px;
    }


}

footer{
    background-color: #1C3849;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
}

.footer-container{
    width: 100%;
    height: 761px;
    box-sizing: border-box;
    max-width: 1200px;
    margin: auto 120px;
    padding-top: 108px;
}

.footer-head {
    width: 100%;
    height: 138px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.footer-head span {
    width: 905px;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 115%;
    text-transform: uppercase;
    color: #F5F5F5;
}

.footer-head div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 77px;
    height: 77px;
    border: 1px solid #F5F5F5;
    border-radius: 10000px;
    color: #F5F5F5;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease-in-out;  
}

.footer-head svg {
    position: relative;
    z-index: 4;
}

.footer-head div:hover {
    color: #1C3849;
    transform: scale(1.3);
}

.footer-head div::before{
    content: '';
    position: absolute;
    transform-origin: center;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    border-radius: 1000px;
    z-index: 3;
    transform: scale(0);
    transition: transform 0.4s ease;   
    cursor: pointer;

}

.footer-head div:hover::before{
    transform: scale(1.2);
}

.footer-main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 72px;
    width: 100%;
}

.footer-site-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 437px;
}

.footer-site-info div {
    display: flex;
    flex-direction: column;
    margin-bottom: 31px;
}

.footer-site-info span {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    text-transform: uppercase;
    color: #F5F5F5;
    opacity: 0.5;
    margin-bottom: 4px;
}

.footer-site-info a {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 110%;
    text-decoration: none;
    color: #F5F5F5;
    cursor: pointer;
    transition: all 0.3s ease-in-out;  
}

.footer-site-info a:hover{
    opacity: 50%;
}

.footer-nav-and-socmedia {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 315px;
}

.footer-map{
    width: auto;
    display: flex;
    flex-direction: row !important;
    margin-top: -22px !important;
    margin-bottom: 0!important;
    cursor: pointer;
}

.footer-map img{
    max-width: 16px;
    margin-right: 8px;
}

.footer-map a{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #F5F5F5;
}


.footer-nav{
    margin-right: 83px;
}

.footer-nav ul{
    list-style: none;
    padding: 0;
    margin: 24px 0 0 0;
}

.footer-nav li {
    margin-bottom: 8px;
}

.footer-nav a {
    text-decoration: none;
    color: #F5F5F5;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    transition: all 0.3s ease-in-out;  
}

.footer-nav a:hover {
    opacity: 0.5;
}

.footer-subtitle {
    display: block;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    text-transform: uppercase;
    color: #F5F5F5;
    opacity: 0.5;
    width: 68px;
}

.footer-socmedia ul{
    list-style: none;
    padding: 0;
    margin: 16px 0 0 0;
}

.footer-socmedia li{
    width: 98px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:#F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 1000px;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    isolation: isolate;
}

.footer-socmedia a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #F5F5F5;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease-in-out;  
}

.footer-socmedia li:hover a{
    color:#1C3849;
}

.footer-socmedia li::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 120%;
    height: 100%;
    background-color: #F5F5F5;
    border-radius: 1000px;
    z-index: 1;
    transition: transform 0.5s;
    transform: translateX(100%);
    cursor: pointer;
}

.footer-socmedia li:hover::before{
    transform: translateX(0);
}

.footer-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #F5F5F5;
    opacity: 0.4;
    margin-top: 34px;
}

.footer-bottom-right-side {
    width: 315px;
    display: flex;
    justify-content: space-between;

}

.footer-bottom a {
    color: inherit;
    text-decoration: none;
    margin-right: 32px;
    white-space: nowrap;
}

/* .ua-footer-span {
    margin-right: 605px;
}

.en-footer-span {
    margin-right: 563px;
}

.ru-footer-span {
    margin-right: 573px;
} */

@media (max-width: 1020px) {
    .footer-container {
        width: 95%;
    }

    .footer-head span {
        width: 810px;
    }

    .ru-footer-span{
        margin-right: 250px;
    }

    .ua-footer-span{
        margin-right: 300px;
    }

    .ru-footer-span{
        margin-right: 250px;
    }

    .en-footer-span{
        margin-right: 350px;
    }
}

@media (max-width: 920px) {

    .footer-head span {
        width: 527px;
        font-size: 35px;
    }

    .footer-site-info {
        max-width: 320px;
    }

    .footer-main {
        margin-top: 10px;
    }

    .footer-bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 10px !important;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        margin-top: 64px;
        gap: 8px;
    }
}

@media (max-width: 870px) {
    .footer-nav {
        margin-right: unset;
    }

    .footer-nav-and-socmedia {
        width: auto;
        gap: 30px;
    }

    .footer-container {
        margin: 0px 40px 0px 40px;
    }
}


@media (max-width: 720px) {
    footer {
        position:relative;
        z-index: 1;
    }

    .footer-head div {
        display: none;
    }

    .footer-head span {
        width: 100%;
    }

    .footer-container{
        width: 90%;
        height: 964px;
        box-sizing: border-box;
        margin: auto 16px;
        padding-top: 59px;
    }

    .footer-main {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .footer-site-info {
        width: 100% !important;
        margin-right: 0px;
    }

    .footer-bottom span{
        margin-right: 0px;
    }

    .footer-main {
        margin-top: 32px;
        width: 100%;
    }

    .footer-site-info a {
        font-size: 20px;
        width: 220px;
    }

    .footer-map a {
        font-size: 12px;
    }

    .footer-nav-and-socmedia{
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }

    .footer-bottom {
        margin-top: 32px !important;
    }

}