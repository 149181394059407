.privacy-policy {
    width: 100%;
    height: auto;
    padding-bottom: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #F5F5F5;
}


.privacy-policy-container {
    width: 40%;
    height: 100%;
    color: rgba(4, 35, 54, 1);
}

.privacy-policy-container h2{
    margin: 80px 0 48px 0;
    width: 60%;
    font-family: NAMU;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-transform: uppercase;
}

.privacy-policy-container h3, 
.privacy-policy-container h4 {
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.privacy-policy-container h3 {
    margin: 48px 0 16px 0;
}

.privacy-policy-container h4 {
    margin: 8px 0 16px 0;
}

.privacy-policy-container h5 {
    margin: 48px 0 0 0;
    color: #3693C8;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
    letter-spacing: 1.8px;
    text-transform: uppercase;
}

.privacy-policy-container p {
    margin-bottom: 0px;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
}

.privacy-policy-container ul {
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    opacity: 0.7;
}

@media (max-width: 700px) {
    .privacy-policy-container{
        width: 80%;
    }

    .privacy-policy-container h2 {
        font-size: 26px;
        width: 100%;
    }

}

@media (max-width: 320px) {
    .privacy-policy-container h2 {
        font-size: 18px;
    }
}





