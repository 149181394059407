.carousel {
    padding: 192px 0 186px 0px;
    width: 100%;
    overflow: hidden;
    color: #393939;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #F5F5F5;
}

.carousel-container {
    margin-left: 9%;
    width: 100%;
    max-width: 1320px;
}

.carousel h5 {
    margin: 0 0 40px 0;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 115%;
    text-transform: uppercase;
}

.carousel .slick-slide {
    background: transparent !important;
    width: 509px !important;
    height: auto !important;
}

.carousel .slick-slider{
    width: 509px !important;
}

.carousel .slick-track{
    display:flex !important;
    width: auto !important;
}

.carousel .slick-arrow{
    top: -64px !important;
    width: 77px !important;
    height: 77px !important;
    border: 1px solid #042336 !important;
    border-radius: 10000px;
    color: #042336 !important;
    transition: all 0.4s ease;  
    scale: 1;
    transform-origin: top !important;
}


.carousel .slick-arrow:hover {
    scale: 1.3;
}

.carousel .slick-arrow::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #042336;
    border-radius: 1000px;
    z-index: -1;
    transform: scale(0);
    transition: transform 0.4s ease; 
    cursor: pointer;
}

.carousel .slick-arrow:hover::after {
    transform: scale(1.2);
}

.carousel .slick-prev {
    left: 410px !important;
    color: #042336 !important;
}

.carousel .slick-prev::before {
    content: url('../../../images/left-icon.svg') !important;
    color: #042336 !important;
    opacity: 1 !important;
}

.carousel .slick-prev:hover:before {
    filter:   saturate(0) invert(100%);
    color: #F5F5F5 !important;
}

.carousel .slick-next {
    left: 510px !important;
    color: #042336 !important;
}

.carousel .slick-next::before {
    content: url('../../../images/right-icon.svg') !important;
    color: #042336 !important;
    opacity: 1 !important;
}

.carousel .slick-next:hover:before {
    filter:   saturate(0) invert(100%);
    color: #F5F5F5 !important;
}


.carousel .slick-disabled{
    border: 1px solid rgba(35, 35, 35, 0.5) !important;
    filter:   saturate(0) invert(100%);
    color: #F5F5F5 !important;
}

.carousel .slick-disabled:hover{
    scale: 1;
}

.carousel .slick-disabled:hover::before{
    filter: invert(0%);
    color: #F5F5F5 !important;
}

.carousel .slick-disabled:hover::after{
    transform: scale(0);
}

.carousel .slick-list {
    overflow: visible !important;
}

.carousel-item {
    width: 425px;
    height: 290px;
    color: #042336;
    background: #EBEBEB;
    margin-right: 24px;
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.carousel-item:hover {
    background: #D6D6D6;
}

.carousel-item-link span {
    transform: translateX(0);
    transition: transform 0.5s;
}

.carousel-item-link svg {
    margin-left: 8px;
    transform: translateX(0);
    transition: transform 0.5s;
}

.carousel-item:hover .carousel-item-link span{
    transform: translateX(-2px);
}

.carousel-item:hover .carousel-item-link svg{
    transform: translateX(3px);
}

.carousel-item-title{
    width: 100%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 155%;
    color: #575757;
}

.carousel-item-bottom-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.carousel-item-text{
    width: 224px;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 125%;
    color: #000000;
}

.carousel-item-link{
    display: flex;
    align-items: center;
    color: #042336;
}

/* .carousel-item:hover .carousel-item-link span{
    animation:  carousel-item-link-hover 0.5s forwards;
} */



/* @keyframes carousel-item-link-hover {
    0% {
      transform: translateX(0);
    }
    70% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  } */

@media (max-width: 600px){
    .carousel {
        width: 100%;
        padding: 70px 0 90px 0px;
    }

    .carousel .slick-list {
        overflow: visible !important;
    }

    .carousel h5{
        font-size: 24px;
    }

    .carousel-item {
        width: 283px;
        height: 290px;
        margin-right: 8px;
        
    }

    .carousel-item-bottom-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .carousel-item-link{
        margin-top: 33px;
    }

    .carousel .slick-arrow {
        display: none !important;
    }

    .carousel .slick-slider{
        width: 351px !important;
    }
    
    
}

