.partners-block{
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.partners-block-container{
    width: 100%;
    height: 1300px;
    padding-top: 269px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #042336;
    position: relative;
    overflow: hidden;
}

.logos {
    width: 2544px;
    margin: 0px 0 210px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    animation: 30s slide infinite linear;
}

.logos.reverse {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    animation: 30s slide-reverse infinite linear;
}


.partners-block-container h5 {
    color: #F5F5F5;
    margin: 0;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 115%;
    text-transform: uppercase;
    position: absolute;
}

.partners-block-container h5:nth-child(1) {
    top: 167px;
    left: 9%;
}

.partners-block-container h5:nth-child(2) {
    top: 167px;
    right: 9%;
}

.partners-block-container h5:nth-child(3) {
    top: 557px;
    right: 9%;
}

.partners-block-container h5:nth-child(4) {
    top: 947px;
    right: 9%;
}

.logos-slide{
    display: flex;
    flex-direction: row;
}

.logo-company{
    width: 180px;
    height: 180px;
    margin: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    border-radius: 10000px;
}

.logo-company img {
    max-width: 80%;
}

@keyframes slide {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@keyframes slide-reverse {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@media (max-width: 900px){
    .partners-block-container{
        height: 779px;
        padding-top: 176px;
    }

    .logo-company{
        width: 90px;
        height: 90px;
    }

    .logo-company img{
        transform: scale(0.5);
    }

    .logos {
        width: 1464px;
        margin-bottom: 143px;
        animation: 20s slide-mobile infinite linear;
    }

    .logos.reverse {
        animation: 20s slide-reverse-mobile infinite linear;
    }

    .partners-block-container h5 {
        font-size: 20px;
    }

    .partners-block-container h5:nth-child(1) {
        top: 75px;
        left: 4%;
        font-size: 24px;
    }
    
    .partners-block-container h5:nth-child(2) {
        top: 290px;
        left: 4%;
    }
    
    .partners-block-container h5:nth-child(3) {
        top: 523px;
        left: 4%;
    }
    
    .partners-block-container h5:nth-child(4) {
        top: 756px;
        left: 4%;
    }

    .logo-company img {
        max-width: 90%;
    }
    
}

@keyframes slide-mobile {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slide-reverse-mobile {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}