.services-block{
    display: flex;
    justify-content: center;
    padding-top: 64px;
    margin-top: -20vh;
}

.services-block-container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    box-sizing: border-box;
    max-width: 1200px;
    margin: auto 120px;
    padding-bottom: 70px;
    color: #042336;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.services-wrapper {
    /* margin-top: 150px; */
    position: relative;
    z-index: 20;
}

.services-block-container.end-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.services-block-container h3{
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 115%;
    position: sticky;
    top: 65px;
    text-transform: uppercase;
    margin: 50px 0 0 0;
    width: 300px;
    height: 50px;
    z-index: 20;
}

/* Styles For Service Item */

.service-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 760px;
    height: 136px;
    padding: 0px 16px;
    color: #042336;
    border-bottom: 1px solid rgba(35, 35, 35, 0.1);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: transform 0.4s ease;
    transition: color 0.4s ease-in-out;
}

.service-item-info{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.service-item-info span {
    margin-right: 32px;
}

.service-item span{
    opacity: 0.3;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 115%;
    white-space: nowrap;
}

.service-item h4 {
    margin: 0;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 115%;
    position: relative;
    z-index: 3;
}

.service-item-link{
    display: flex;
    align-items: center;
}

.service-item-link span{
    font-size: 13px;
    font-weight: 400;
    opacity: 1;
    position: relative;
    z-index: 3;
    transition: transform 0.4s ease;
    transform: translateX(0px);
}

.service-item-link svg{
    margin-left: 14px;
    margin-bottom: 3px;
    position: relative;
    z-index: 3;

}

.service-item::before{
    content: '';
    position: absolute;
    left:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #042336;
    z-index: 0;
    animation-name: moveBefore;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    transform: translateY(100%);
    cursor: pointer;
}

.service-item.hovered{
    color: #F5F5F5;
}

.service-item.hovered::before{
    animation-name: moveBeforeHover;
}

.service-item.hovered .service-item-link span{
    transform: translateX(-5px);
}

a {
    text-decoration: none;
}

.second-3d {
    position: absolute;
    left: -3%;
    top: 0%;
    width: 60%;
    height: 100%;
    z-index: 1;
}

@keyframes moveBefore {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
  
  @keyframes moveBeforeHover {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
/* End Styles For Service Item */


@media (max-width: 1200px) {
    .services-block-container {
        display: flex;
        flex-direction: column;
        margin: auto 100px;
        padding-bottom: 87px;
    }

    .services-block-container h3 {
        position: static;
    }

    .services-block-container.end-flex {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .service-item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: 100%;
        padding: 24px 0;
        height: 88px;
    }

    .service-item.hovered .service-item-link span{
        transform: translateX(0px);
    }

    .second-3d {
        display: none;
    }
}

@media (max-width: 900px) {
    .services-block-container {
        margin: auto 16px;
        justify-content: start;
    }

    .service-item-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .services-block-container h3{
        font-size: 24px;
        margin: 25px 0 0 0;
    }

    .service-item h4{
        font-size: 20px;
    }

    .services-wrapper {
        margin-top: 12px;
    }
}