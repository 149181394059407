.services-wrapper{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}

.services-wrapper h2 {
    text-align: left;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 120%;
    text-transform: uppercase;
    color: #042336;
    margin: 80px auto 154px auto;
    width: 1225px;
}

@media (max-width: 1000px){
    .services-wrapper h2{
        width: 343px;
        margin: 74px auto 150px 16px;
        font-size: 40px;
    }

    .services-wrapper{
        margin-bottom: 95px;
        margin-top: 0;
    }
}